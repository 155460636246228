import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';

// MISTable Component
const MISTable = ({ data, onActivate, onConnect, onCreateWorkflow }) => (
  <table className="min-w-full bg-gray-700 rounded-lg shadow-lg border-collapse">
    <thead>
      <tr className="bg-gray-600 text-gray-200">
        <th className="py-2 px-3 border border-gray-500 text-left">Service</th>
        <th className="py-2 px-3 border border-gray-500">Active</th>
        <th className="py-2 px-3 border border-gray-500">Credentials</th>
        <th className="py-2 px-3 border border-gray-500">Status</th>
        <th className="py-2 px-3 border border-gray-500">Actions</th>
      </tr>
    </thead>
    <tbody>
      {data.map((row, index) => (
        <tr key={index} className="hover:bg-gray-600">
          <td className="py-2 px-3 border border-gray-500">{row.service}</td>
          <td className="py-2 px-3 border border-gray-500 text-center">
            <input
              type="checkbox"
              checked={row.active}
              onChange={() => onActivate(index)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
          </td>
          <td className="py-2 px-3 border border-gray-500">{row.credentials || '-'}</td>
          <td className="py-2 px-3 border border-gray-500 text-center">
            {row.status === 'connected' ? (
              <CheckCircle className="inline-block text-green-500" size={20} />
            ) : (
              <XCircle className="inline-block text-red-500" size={20} />
            )}
          </td>
          <td className="py-2 px-3 border border-gray-500">
            <button
              onClick={() => onConnect(index)}
              className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
              disabled={!row.active}
            >
              Connect
            </button>
            <button
              onClick={() => onCreateWorkflow(index)}
              className="bg-green-500 text-white px-2 py-1 rounded"
              disabled={!row.active || row.status !== 'connected'}
            >
              Create Workflow
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

// Modal for Client ID, Client Secret, and Gmail API Name Input
const ClientIdModal = ({ isOpen, onClose, onSubmit }) => {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [gmailApiName, setGmailApiName] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-700">
        <h3 className="text-lg font-medium leading-6 text-white">Connect Gmail</h3>
        <div className="mt-2">
          <input
            type="text"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            placeholder="Client ID"
            className="mt-2 p-2 w-full bg-gray-600 text-white rounded"
          />
          <input
            type="text"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
            placeholder="Client Secret"
            className="mt-2 p-2 w-full bg-gray-600 text-white rounded"
          />
          <input
            type="text"
            value={gmailApiName}
            onChange={(e) => setGmailApiName(e.target.value)}
            placeholder="Gmail API Name"
            className="mt-2 p-2 w-full bg-gray-600 text-white rounded"
          />
          <button
            onClick={() => onSubmit(clientId, clientSecret, gmailApiName)}
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            Generate Authorization URL
          </button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white p-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Modal for Access Token and Credential Generation
const AccessTokenModal = ({ isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-700">
        <h3 className="text-lg font-medium leading-6 text-white">Generate Access Token and Create Credentials</h3>
        <div className="mt-2">
          <button
            onClick={onSubmit}
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            Get Access Token and Create Credentials
          </button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white p-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Main App Component
const App = () => {
  const [isClientIdModalOpen, setIsClientIdModalOpen] = useState(false);
  const [isAccessTokenModalOpen, setIsAccessTokenModalOpen] = useState(false);
  const [authorizationCode, setAuthorizationCode] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [gmailApiName, setGmailApiName] = useState('');
  const [tableData, setTableData] = useState([
    { service: 'Gmail', active: false, credentials: '', status: 'disconnected' },
    { service: 'Hubspot', active: false, credentials: '', status: 'disconnected' },
    { service: 'Github', active: false, credentials: '', status: 'disconnected' },
    { service: 'Google Analytics', active: false, credentials: '', status: 'disconnected' },
    { service: 'LinkedIn', active: false, credentials: '', status: 'disconnected' },
    { service: 'Canvas', active: false, credentials: '', status: 'disconnected' },
    { service: 'Google Search Console', active: false, credentials: '', status: 'disconnected' },
    { service: 'Instagram', active: false, credentials: '', status: 'disconnected' },
    { service: 'Website Search', active: false, credentials: '', status: 'disconnected' },
  ]);

  // Handle Activate Toggle
  const handleActivate = (index) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[index].active = !newData[index].active;
      return newData;
    });
  };

  // Step 1: Handle connect button, show first modal to get Client ID, Client Secret, Gmail API Name
  const handleConnect = (index) => {
    setIsClientIdModalOpen(true);
  };

  // Step 2: Generate Authorization URL
  const generateAuthorizationUrl = (clientId, clientSecret, gmailApiName) => {
    // Save Client ID and Secret in localStorage before redirect
    localStorage.setItem('clientId', clientId);
    localStorage.setItem('clientSecret', clientSecret);
    localStorage.setItem('gmailApiName', gmailApiName);

    const redirectUri = 'https://foundercommandcentre.com/rest/oauth2-credential/callback';
    const scope =
      'https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/gmail.addons.current.action.compose https://www.googleapis.com/auth/gmail.addons.current.message.action https://mail.google.com/ https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose';
    const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=consent`;

    // Redirect to the authorization URL
    window.location.href = url;
  };

  // Step 3: Handle callback and extract authorization code from URL and get client ID/Secret from localStorage
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    if (code) {
      setAuthorizationCode(code);
      setClientId(localStorage.getItem('clientId') || '');
      setClientSecret(localStorage.getItem('clientSecret') || '');
      setGmailApiName(localStorage.getItem('gmailApiName') || '');
      setIsAccessTokenModalOpen(true); // Show the modal to generate access token
    }
  }, []);

  // Step 5: Generate Access Token and Create Credentials with Debugging Logs and CORS Proxy
  const generateAccessTokenAndCreateCredentials = async () => {
    const tokenUrl = 'https://oauth2.googleapis.com/token';
    const redirectUri = 'https://foundercommandcentre.com/rest/oauth2-credential/callback'; // Must match the original redirect URI

    // Debug logging of parameters
    console.log("Client ID:", clientId);
    console.log("Client Secret:", clientSecret);
    console.log("Authorization Code:", authorizationCode);
    console.log("Redirect URI:", redirectUri);

    const body = new URLSearchParams({
      client_id: clientId,
      client_secret: clientSecret,
      code: authorizationCode,
      grant_type: 'authorization_code',
      redirect_uri: redirectUri,
    });

    try {
      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body.toString(),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Access Token:', data.access_token);

        // Now store credentials in the API using a CORS proxy
        const corsProxy = 'https://cors-anywhere.herokuapp.com/';
        const apiUrl = corsProxy + 'https://lead.marketleap.in/api/v1/credentials';
        console.log("Sending POST request to:", apiUrl);
        console.log("With data:", {
          name: gmailApiName,
          type: 'gmailOAuth2',
          data: {
            clientId: clientId,
            clientSecret: clientSecret,
            oauthTokenData: {
              accessToken: data.access_token,
            },
          },
        });

        const credentialResponse = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'X-N8N-API-KEY':
              'n8n_api_647d76c6f0f61b1d4dfe2d3eeeae23ced360e32dfd0d8071865f26edcc50cbae17590da59105f751',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: gmailApiName,
            type: 'gmailOAuth2',
            data: {
              clientId: clientId,
              clientSecret: clientSecret,
              oauthTokenData: {
                accessToken: data.access_token,
              },
            },
          }),
        });

        if (credentialResponse.ok) {
          const credentialData = await credentialResponse.json();
          console.log("Credential Data Received:", credentialData);
          setTableData((prevData) => {
            const newData = [...prevData];
            const index = newData.findIndex((item) => item.service === 'Gmail');
            newData[index].credentials = credentialData.id;
            newData[index].status = 'connected';
            return newData;
          });
          alert('Service connected successfully!');
        } else {
          console.error('Error storing credentials:', credentialResponse.status);
        }
      } else {
        console.error('Error fetching token:', response.status);
      }
    } catch (error) {
      console.error('Network or other error:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-800 text-gray-200 p-8">
      <h1 className="text-3xl font-bold mb-4">MIS Dashboard</h1>

      <MISTable
        data={tableData}
        onActivate={handleActivate}
        onConnect={handleConnect}
        onCreateWorkflow={() => console.log('Create Workflow')}
      />

      {/* First Modal: Get Client ID, Client Secret, and Gmail API Name */}
      <ClientIdModal
        isOpen={isClientIdModalOpen}
        onClose={() => setIsClientIdModalOpen(false)}
        onSubmit={generateAuthorizationUrl}
      />

      {/* Second Modal: Get Access Token and Create Credentials */}
      <AccessTokenModal
        isOpen={isAccessTokenModalOpen}
        onClose={() => setIsAccessTokenModalOpen(false)}
        onSubmit={generateAccessTokenAndCreateCredentials}
      />
    </div>
  );
};

export default App;
